import React from "react"
import Layout from "../components/layout"
import { graphql, PageProps } from "gatsby"
import Image from "../components/image"

import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import SEO from "../components/seo"

export default ({ data }: PageProps<Queries.BookmarkManagerPageQuery>) => (
  <Layout webStoreImage={
    <Image image={data.webStore?.childImageSharp?.gatsbyImageData}
           alt="Available in the Chrome Web Store" />
  }>
    <SEO
      canonical="/bookmark-manager"
      title="The best bookmark manager for Google Chrome: Rewind"
      description="Rewind is the best bookmark manager for Google Chrome. It's free, easy to use, and comes with a number of features that make it the best choice for anyone who wants to get their bookmarking under control."
    />
    <div className="container bookmark-manager-page">
      <h1>The Best Bookmark Manager<br />for Google Chrome: Rewind</h1>
      <p>
        If you're like most people, you probably have too many bookmarks. It's tough to keep track of them all, and even
        more to find the ones you need when you need them. That's where Rewind comes in.
      </p>
      <p>
        Rewind is the best bookmark manager for Google Chrome. It's free, easy to use, and comes with a number of
        features
        that make it the best choice for anyone who wants to get their bookmarking under control.
      </p>
      <Zoom overlayBgColorEnd="#001F29CC" overlayBgColorStart="#001F29CC">
        <Image image={data.bookmarkManager?.childImageSharp?.gatsbyImageData}
               alt="Rewind Bookmark Manager screenshot" />
      </Zoom>
      <h2>Rewind's most-liked features</h2>
      <p>
        Here are just a few of the things that make Rewind the best bookmark manager for Google Chrome:
      </p>
      <ul>
        <li>
          <h3>Thumbnails</h3>
          <p>Rewind automatically generates thumbnails for your bookmarks, so you can easily find the one
            you're looking for.</p>
        </li>
        <li><h3>Search</h3>
          <p>Rewind's powerful search feature lets you find the bookmark you need, even if you can't remember the
            exact URL.</p>
        </li>
        <li>
          <h3>Sort by date</h3>
          <p>Rewind automatically sorts your bookmarks by date, so you can quickly find the ones you've
            recently added.</p>
        </li>
        <li><h3>Discovery</h3>
          <p>Rewind shows you the bookmark you saved today, one year ago at the same date, the year before and so on, on
            the same page. It makes discovering your old bookmarks really fun and gives you a selection of content that
            you like everyday!</p>
        </li>
      </ul>

      <p>
        There's no reason to keep using a bookmark manager that doesn't work well. With Rewind, you'll be able to get
        your
        bookmarks under control and keep track of them easily. Try Rewind today, and see how it can help you get the
        most
        out of your bookmarking.
      </p>
    </div>

  </Layout>
)

export const query = graphql`
    query BookmarkManagerPage {
        webStore: file(relativePath: {eq: "chrome-web-store-button@2x.png"}) {
            childImageSharp {
                gatsbyImageData(width: 196, quality: 100, placeholder: NONE, layout: FIXED)
            }
        }
        bookmarkManager: file(relativePath: {eq: "rewind-screenshot.png"}) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`